footer {
    padding-top: 30px;
    text-align: start;
    color: var(--textColor);
}

footer .footLogo img {
    width: 200px;
    height: 150px;
    object-fit: contain;
    margin-right: auto;
}

footer h2 {
    font-family: var(--displayFont);
    font-weight: bold;
    margin-bottom: 27px;
}

footer ul {
    padding-inline-start: 0;
    margin-bottom: 0;
    list-style-type: none;
}

footer ul li {
    padding-bottom: 10px;
}

footer ul li a {
    color: var(--textColor);
    position: relative;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
}

footer .footLinks ul li {
    padding-right: 45px;
}

footer .footLinks ul:last-child li {
    padding: 0 0 0 15px;
}

footer .footSocial .worldIcon {
    font-size: 30px;
}

footer .footSocial img {
    width: 25px;
    height: 25px;
}

footer ul li a:hover {
    text-decoration: none;
    color: var(--textColor);
}

footer ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--textColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

footer ul li a:hover:after {
    width: 100%;
    left: 0;
}

footer .footMisc .footSocial li:not(:last-child) {
    margin-inline-end: 30px;
}

footer .footMisc .footSubscribe form {
    background: #3a658e;
    border-radius: 5px;
    height: auto;
    flex-wrap: nowrap;
}

footer .footMisc .footSubscribe form {
    margin-bottom: 5px;
}

footer .footMisc .footSubscribe form .customInput {
    margin-bottom: 0;
}

footer .footMisc .footSubscribe form .customInput {
    background: inherit;
    border: none;
    color: var(--textColor);
    height: inherit;
}

.footSubscribe .MuiFormLabel-root {
    color: var(--textColor);
}

footer .footMisc .footSubscribe form .subsInp {
    align-self: stretch;
}

footer .footMisc .footSubscribe form button {
    height: auto;
    align-self: stretch;
}

footer .footMisc .footSubscribe form .MuiFormHelperText-root.Mui-error {
    color: #000000;
    background: #ff616163;
    margin: 0;
    padding: 3px 10px;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:before {
    border-bottom: transparent;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footMisc .footSubscribe form input:focus {
    box-shadow: none;
    border: none;
}

footer .footMisc .footSubscribe form input::placeholder {
    color: var(--textColor);
}
footer .footMisc .footSubscribe form button {
    color: var(--textColor);
    display: flex;
}
footer .footMisc .footSubscribe small {
    color: var(--textColor);
}

footer .footCopyright {
    background: var(--textColor);
    font-size: 18px;
    padding: 15px;
    margin: 35px 0 0;
    color: #fff;
}

footer .poweredBy {
    font-size: 18px;
    padding: 15px;
    color: #3f3d3d;
    font-weight: bold;
    text-align: center;
}

footer .hashTag {
    color: var(--textColor);
    margin: 0 0 15px 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .footCnt {
    border-top: 1px solid #e7e7e7;
    padding-top: 30px;
}

footer .footLogo {
    margin-bottom: 40px;
}

.footer-brands {
    margin: 35px auto;
    align-items: center;
}

.container.footContainer {
    max-width: 860px;
}

@media (max-width: 1200px) {
    .footCnt .footLogo {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0 auto 40px;
    }
}

@media (max-width: 767px) {
    footer .footCnt {
        padding-top: 0;
    }
    .footCnt .footLinks,
    .footCnt .footMisc {
        width: 100%;
    }
    footer .footMisc .footSubscribe form .subsInp {
        width: 90%;
    }
    footer .footMisc .footSubscribe button {
        min-width: auto;
    }
    footer .footSocial {
        justify-content: center !important;
        margin: 10px auto;
    }
    footer .footCopyright {
        margin-top: 25px;
        text-align: center !important;
        font-size: 14px;
    }
    footer .footMisc .footSubscribe form {
        flex-wrap: nowrap;
    }
    footer .hashTag {
        margin-top: 10px;
    }
    footer .hashTag,
    footer .poweredBy {
        text-align: center;
        font-size: 14px;
        padding: 0;
        margin: 0;
    }
    footer .footLinks ul {
        justify-content: space-around;
        margin-bottom: 20px;
    }
    footer .footLogo img {
        margin-left: auto;
    }

    .footer-brands li {
        width: 100%;
    }

    .footer-brands li img {
        max-width: 90%;
        margin: auto;
        display: block;
    }
    .footer-brands {
        margin: 15px auto;
        align-items: center;
    }
    footer {
        padding-top: 0;
    }
}

@media (max-width: 479px) {
    footer .footCopyright {
        font-size: 12px;
        padding: 10px 7px !important;
    }
    footer ul li a {
        font-size: 13px;
    }
    footer .footMisc .footSubscribe small {
        text-align: center;
        width: 100%;
        display: block;
    }
    footer .footLinks ul:not(:last-child) {
        margin-inline-end: 35px;
    }
}
